import React from 'react';
import AudioBox from './AudioBox';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class AudioBoxContainer extends React.Component {
    render() {
        return (
            <AudioBox {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.app.listView,
        screenSize: state.app.screenSize,
        audios: state.app.audioData,
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(AudioBoxContainer)