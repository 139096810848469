import React from 'react';
import Audio from './Audio';
import { connect } from 'react-redux';
import { 
    setSearchCount
} from './../store/filter/actions';


/**
 * Middleware to leave stupid component and clever.
 */
class AudioContainer extends React.Component {
    render() {
        return (
            <Audio {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
        shelvesDataLoaded: state.app.shelvesDataLoaded,
        allCardsLoaded: state.app.allCardsLoaded,
        order: state.filter.order,
        searchString: state.filter.searchString,
        searchCount: state.filter.searchCount,
        switchStatus: state.filter.switch,
        filters: state.filter.dguvFilter,
        audios: state.app.audioData,
        hero: state.app.heroData
    }
}
  
const mapDispatchToProps = {
    setSearchCount 
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AudioContainer)