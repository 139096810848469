import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import './AudioBox.sass'
import PropTypes from 'prop-types'
import Carousel from "react-bootstrap/Carousel";



export default function AudioBox(props) {

    function chunkSplit(arr, size) {
        if (size <= 0) {
            throw new Error();
        }
        let outputArray = [];
        // Then slice to arrays for correct carousel
        for (let i = 0; i < arr.length; i += size) {
            outputArray.push(arr.slice(i, i + size));
        }
        return outputArray;
    }

    /**
     * Function to show cards list with header includes title and link. 
     * Link is optional.
     * 
     * @param {string} link — link (optional).
     * @param {string} title — title to show.
     * @param {array} cards — cards list.
     */
    return (
        <div className="group-box">
            <div className="group-box__header">
                <h3 className="group-box__header-title">
                    Audios
                </h3>
                <NavLink
                    className="group-box__quantity"
                    to={ '?format=audio' }>
                    { i18next.t('All', { size: props.audios.length }) }
                    <svg className="arrow" height="13" width="13">
                        <title>Weiter</title>
                        <use href="svg/bootstrap-icons.svg#chevron-right">
                        </use>
                    </svg>
                </NavLink>
            </div>
            <div
                className={`cards-carousel`}>
                <Carousel
                    className={`cards-carousel__carousel slide cards-carousel__carousel--non-carousel'}`}
                    interval={ 10000 }
                    touch={ true }
                >
                    { chunkSplit(props.audios, 4).map((audioChunk, index) => (
                        <Carousel.Item
                            key={ index }
                            className="cards-carousel__carousel-item">
                            <div className="cards-carousel__carousel-cards">
                                <div style={ {display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', marginTop: '1rem', padding: '0 1rem'} }>
                                    {
                                        audioChunk.map((item) => <div style={{
                                            borderRadius: '20px',
                                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.25)',
                                            overflow: 'hidden',
                                            padding: '0.75rem',
                                            display: 'grid',
                                            gridTemplateColumns: '120px 1fr',
                                            gap: '1rem',
                                        }}>
                                            <div style={{backgroundImage: 'url(' + item.cover + ')', backgroundSize: 'cover', height: '120px', borderRadius: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}></div>
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                                <div>
                                                    <h5><a href={item.url}>{item.title}</a></h5>
                                                    <div style={{fontSize: '80%', fontWeight: 'bold'}}>
                                                        {item.room}
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '1em', height: '1em', verticalAlign: '-0.1em', margin: '0 0.5em'}}>
                                                            <path fill="currentColor"
                                                                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                                        </svg>
                                                        {item.group} {item.parent ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '1em', height: '1em', verticalAlign: '-0.1em', margin: '0 0.5em'}}>
                                                        <path fill="currentColor"
                                                              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                                    </svg> : ''} {item.parent}</div>
                                                </div>
                                                <audio controls style={{width: '100%'}}><source src={item.src} /></audio>
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </Carousel.Item>
                    )) }
                </Carousel>
            </div>

        </div>
    )
}

AudioBox.propTypes = {
    cards: PropTypes.array,
    listView: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    link: PropTypes.string,
    title: PropTypes.string,
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
}