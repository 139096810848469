import React from 'react';
import i18next from 'i18next';
import {NavLink} from 'react-router-dom';
import Header from './../components/common/Header/HeaderContainer';
import Footer from './../components/common/Footer/Footer';
import MobileNav from './../components/mobile/MobileNav/MobileNavContainer';
import inResponsiveSizes from './../lib/ui/inResponsiveSizes';
import './Category.sass';
import {useState, useRef} from 'react';
import {useEffect} from 'react';
import PropTypes from 'prop-types'
import Search from "../components/common/Search/SearchContainer";


export default function AudioLayout(props) {

    const containerRef = useRef(null)
    const [, setIsVisible] = useState(false)

    const callbackFunction = (entries) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    }
    
    const filteredAudios = () => {
        const search = props.searchString?.toLowerCase();
        return props.audios.filter(item => {
            return !search
            || item.title.toLowerCase().includes(search)
            || item.room.toLowerCase().includes(search)
            || item.group.toLowerCase().includes(search)
            || item.parent?.toLowerCase().includes(search)
        })
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        const cntRef = containerRef.current
        if (containerRef.current) {
            observer.observe(containerRef.current)
        }
        return () => {
            if (cntRef) {
                observer.unobserve(cntRef)
            }
        }
    })
    
    const key = "https://repos.rms2cdn.de/files"

    return (
        <>
            <Header/>
            {!inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <div className='filters' style={props.hero ? {backgroundImage: `url(${key}${props.hero.cover})`} : {} }>
                    <h1 className='headline'>Mediathek</h1>
                    <div
                        className={`page__filter-row filter-header ${props.showMoreFilters ? 'filter-header--opened' : ''}`}>
                        <div className='filter-header__sticky'>
                            <Search
                                className="filter-header__search" />
                        </div>
                    </div>
                    {props.showMoreFilters ?
                        <button className="filters__all-close" onClick={() => props.setShowMoreFilters(!props.showMoreFilters)}>Schließen</button>
                        :
                        <></>
                    }
                </div>
            }
            <div className="page__content">
                <section className="category">
                    {/* Backlink */}
                    {props.backlinkMarkup ?
                        <>
                            {props.backlinkMarkup}
                        </>
                        :
                        <NavLink
                            className="category__backlink home-backlink"
                            to={`/`}>
                            {i18next.t('Home')}
                        </NavLink>
                    }

                    {/* Title */}
                    <h1 className="category__title">Audios</h1>


                    
                    {/* Cards list */}

                    <div style={ {display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', marginTop: '1rem', gridColumnEnd: 'span 3'} }>
                    {
                        filteredAudios().map((item) => <div style={{
                            borderRadius: '20px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.25)',
                            overflow: 'hidden',
                            padding: '0.75rem',
                            display: 'grid',
                            gridTemplateColumns: '120px 1fr',
                            gap: '1rem',
                        }}>
                            <div style={{backgroundImage: 'url(' + item.cover + ')', backgroundSize: 'cover', height: '120px', borderRadius: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}></div>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div>
                                    <h5><a href={item.url}>{item.title}</a></h5>
                                    <div style={{fontSize: '80%', fontWeight: 'bold'}}>
                                        {item.room}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '1em', height: '1em', verticalAlign: '-0.1em', margin: '0 0.5em'}}>
                                            <path fill="currentColor"
                                                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                        </svg>
                                        {item.group} {item.parent ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{width: '1em', height: '1em', verticalAlign: '-0.1em', margin: '0 0.5em'}}>
                                        <path fill="currentColor"
                                              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg> : ''} {item.parent}</div>
                                </div>
                                <audio controls style={{width: '100%'}}><source src={item.src} /></audio>
                            </div>
                        </div>)
                    }
                    </div>
                </section>
            </div>
            {inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <MobileNav/>
            }
            <Footer/>
        </>
    )
}

AudioLayout.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    cards: PropTypes.array,
    order: PropTypes.number,
    searchString: PropTypes.string,
    searchCount: PropTypes.number,
    setSearchCount: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    allCardsLoaded: PropTypes.bool,
    backlinkMarkup: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    titleMarkup: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    title: PropTypes.string,
    content: PropTypes.string
}