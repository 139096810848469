import React from 'react';
import Header from './../components/common/Header/HeaderContainer';
import Footer from './../components/common/Footer/Footer';
import MobileNav from './../components/mobile/MobileNav/MobileNavContainer';
import inResponsiveSizes from './../lib/ui/inResponsiveSizes';
import PropTypes from 'prop-types'


export default function DefaultLayout(props) {
    return (
        <>
            <Header />
            { props.beforeContent &&
                <div className="page__hero">
                    { props.beforeContent }
                </div>
            }
            <div className="page__content">
                { props.content }
            </div>
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <MobileNav />
            }
            <Footer />
        </>
    )
}

DefaultLayout.propTypes = {
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    beforeContent: PropTypes.object,
    content: PropTypes.object
}