export const APP_DATA_LOADED = 'APP_DATA_LOADED'
export const APP_SHELVES_LOADED = 'APP_SHELVES_LOADED'
export const APP_TITLE = 'APP_TITLE'
export const APP_SHOW_MORE_FILTERS = 'APP_SHOW_MORE_FILTERS'
export const APP_SET_GROUPS_LIST = 'APP_SET_GROUPS_LIST'
export const APP_SET_FORMATS_LIST = 'APP_SET_FORMATS_LIST'
export const APP_SET_CARDS_LIST = 'APP_SET_CARDS_LIST'
export const APP_SCREEN_SIZE = 'APP_SCREEN_SIZE'
export const APP_VIDEO_MODAL_OPENED = 'APP_VIDEO_MODAL_OPENED'
export const APP_VIDEO_MODAL_DATA = 'APP_VIDEO_MODAL_DATA'
export const APP_SOURCE_MODAL_OPENED = 'APP_SOURCE_MODAL_OPENED'
export const APP_SOURCE_MODAL_DATA = 'APP_SOURCE_MODAL_DATA'
export const LOAD_APP_DATA = 'LOAD_APP_DATA'
export const APP_CHANGE_VIEW = 'APP_CHANGE_VIEW'
export const APP_CHANGE_LIST_VIEW = 'APP_CHANGE_LIST_VIEW'
export const APP_SET_SHELVES = 'APP_SET_SHELVES'
export const APP_SET_MOBILE_NAV = 'APP_SET_MOBILE_NAV'
export const ALL_CARDS_LOADED = 'ALL_CARDS_LOADED'
export const APP_SET_HERO_DATA = 'APP_SET_HERO_DATA'
export const APP_SET_AUDIO_DATA = 'APP_SET_AUDIO_DATA'



export const setTitle = (title) => ({
    type: APP_TITLE,
    payload: title
})

export const setMainView = (view) => ({
    type: APP_CHANGE_VIEW,
    payload: view
})

export const setShowMoreFilters = (showMoreFilters) => ({
    type: APP_SHOW_MORE_FILTERS,
    payload: showMoreFilters
})

export const setShelves = (shelves) => ({
    type: APP_SET_SHELVES,
    payload: shelves
})

export const setGroupsList = (groupsList) => ({
    type: APP_SET_GROUPS_LIST,
    payload: groupsList
})

export const setFormatsList = (formatsList) => ({
    type: APP_SET_FORMATS_LIST,
    payload: formatsList
})

export const setCardsList = (cardsList) => ({
    type: APP_SET_CARDS_LIST,
    payload: cardsList
})

export const setScreenSize = (screenSize) => ({
    type: APP_SCREEN_SIZE,
    payload: screenSize
})

export const setOpenVideoModal = (showVideoModal) => ({
    type: APP_VIDEO_MODAL_OPENED,
    payload: showVideoModal
})

export const setVideoModalData = (videoModalData) => ({
    type: APP_VIDEO_MODAL_DATA,
    payload: videoModalData
})

export const setOpenContentModal = (showContentModal) => ({
    type: APP_SOURCE_MODAL_OPENED,
    payload: showContentModal
})

export const setContentModalData = (contentModalData) => ({
    type: APP_SOURCE_MODAL_DATA,
    payload: contentModalData
})

export const setHeroData = (heroData) => ({
    type: APP_SET_HERO_DATA,
    payload: heroData
})

export const getAppData = () => ({
    type: LOAD_APP_DATA
})

export const setAppDataLoaded = () => ({
    type: APP_DATA_LOADED
})

export const setShelvesDataLoaded = () => ({
    type: APP_SHELVES_LOADED
})

export const setAllCardsLoaded = () => ({
    type: ALL_CARDS_LOADED
})

export const setListView = (listView) => ({
    type: APP_CHANGE_LIST_VIEW,
    payload: listView
})

export const setMobileNav = (mobileNav) => ({
    type: APP_SET_MOBILE_NAV,
    payload: mobileNav
})

export const setAudioData = (audioData) => ({
    type: APP_SET_AUDIO_DATA,
    payload: audioData
})